import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  format,
  startOfMonth,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const listMonthsYear = () => {
  const months = Array.from({ length: 12 }, (_, index) => new Date(0, index));

  const monthList = months.map(month =>
    format(month, 'MMMM', { locale: ptBR }),
  );

  return monthList;
};

export const formatDate = (date: Date, returnFormat = 'dd/MM/yyyy') => {
  return format(date, returnFormat, { locale: ptBR });
};

export const addMonthsInDate = (date: Date, numberOfMonths: number) => {
  return addMonths(date, numberOfMonths);
};

export const getAllDaysInMonth = (date: Date) => {
  const firstDayMonth = startOfMonth(date);
  const lastDayMonth = endOfMonth(date);

  const allDaysMonth = eachDayOfInterval({
    start: firstDayMonth,
    end: lastDayMonth,
  });

  return allDaysMonth;
};

export const getStartAndEndOfMonth = (date: Date) => {
  const firstDayOfMonth = startOfMonth(date);
  const lastDayOfMonth = endOfMonth(date);

  return {
    firstDayOfMonth: firstDayOfMonth,
    lastDayOfMonth: lastDayOfMonth,
  };
};
