import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Zoom from '@material-ui/core/Zoom';
import { BsGridFill, BsListUl } from 'react-icons/bs';
import moment from 'moment';
import {
  Container,
  ContainerTitle,
  Content,
  ContainerSearch,
  HeaderDate,
} from './styles';
import * as S from './styles';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import Search from '../../../components/Search';
import { ModalAgendaDash } from '../../../components/ModalAgendaDash';
import { Agendamentos, EmptyAgendamento } from '../../../models/Agendamentos';
import { useAuth } from '../../../hooks/auth';
import IconFilter from '../../../assets/IconFilter.svg';
import { ModalFilter, StatusButton } from '../../../components/ModalFilter';
import { CardEmpty } from '../../../components/CardEmpty';
import { formatDate } from '../../../utils';
import { NewCardAgenda } from './components/NewCardAgenda';
import { filterSchedulesData } from '../../../components/Calendario';

export const DashboardHistAgenda: React.FC = () => {
  const { empresaPrincipal, user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showAgenda, setShowAgenda] = useState<Agendamentos>(EmptyAgendamento);
  const [isModeVisibleCanva, setIsModeVisibleCanva] = useState(true);
  const [agendasAll, setAgendasAll] = useState<Agendamentos[]>([]);
  const [pendingShedule, setPendingShedule] = useState<Agendamentos[]>([]);
  const [inProgressShedule, setInProgressShedule] = useState<Agendamentos[]>(
    [],
  );
  const [completeShedule, setCompleteShedule] = useState<Agendamentos[]>([]);
  const [incompleteShedule, setIncompleteShedule] = useState<Agendamentos[]>(
    [],
  );
  const [search, setSearch] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [searchDataFinal, setSearchDataFinal] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [dataFilterModal, setDataFilterModal] = useState<StatusButton[]>([
    {
      status: true,
      name: 'Colaborador',
    },
    {
      status: false,
      name: 'Filial',
    },
    {
      status: false,
      name: 'Ambiente',
    },
    {
      status: false,
      name: 'Intervalo de datas',
    },
  ]);

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const loadAgenda = useCallback(
    async (dataIniMes: string, dataEndMes: string) => {
      setLoading(true);
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
      const filialId = user.companyId;
      const aux = isCoordenador
        ? `filialId=${filialId}`
        : `companyId=${companyId}`;
      api
        .get(
          `agendas/v2/Agendamentos/company?dataIni=${dataIniMes}&dataFim=${dataEndMes}&${aux}&report=true`, // ${empresaPrincipal[0].Id_Empresas}`,
        )
        .then(response => {
          const dataAgenda: Agendamentos[] = response.data;
          const ageSort = dataAgenda
            .filter(
              item =>
                item.active && item.Agenda.ATIVO && !item.Agenda.deletedAt,
            )
            .sort((a, b) => {
              const valueInitA = Number(
                a.Agenda.Periodicidade.HORA_BASE_INICIAL.split(':').join(''),
              );
              const valueInitB = Number(
                b.Agenda.Periodicidade.HORA_BASE_INICIAL.split(':').join(''),
              );
              if (valueInitA < valueInitB) return -1;
              if (valueInitA > valueInitB) return 1;

              const valueFimA = Number(
                a.Agenda.Periodicidade.HORA_BASE_FINAL.split(':').join(''),
              );
              const valueFimB = Number(
                b.Agenda.Periodicidade.HORA_BASE_FINAL.split(':').join(''),
              );
              if (valueFimA < valueFimB) return -1;
              if (valueFimA > valueFimB) return 1;
              return 0;
            });

          const schedules = ageSort
            .filter(item => item.Agenda.ATIVO || !item.Agenda.deletedAt)
            .map(i => {
              const dateAgendaString = String(i.dataAgend).slice(0, 10)
              return {
                ...i,
                dataAgend: formatDate(new Date(`${dateAgendaString} 12:00:00`), 'yyyy-MM-dd') as any,
              }
            });

          setAgendasAll(schedules);

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);

          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao carregar dados, por favor atualize a página',
          });
        });
    },
    [addToast, empresaPrincipal, isCoordenador, user.companyId],
  );

  const renderMouth = useCallback((mouth: number): string => {
    switch (mouth) {
      case 1:
        return 'Janeiro';
      case 2:
        return 'Fevereiro';
      case 3:
        return 'Março';
      case 4:
        return 'Abril';
      case 5:
        return 'Maio';
      case 6:
        return 'Junho';
      case 7:
        return 'Julho';
      case 8:
        return 'Agosto';
      case 9:
        return 'Setembro';
      case 10:
        return 'Outubro';
      case 11:
        return 'Novembro';
      case 12:
        return 'Dezembro';
      default:
        return '';
    }
  }, []);

  const dateRender = useMemo(() => {
    const [yearStart, mouthStart, dayStart] = searchDataInicial.split('-');
    const [yearEnd, mouthEnd, dayEnd] = searchDataFinal.split('-');
    if (searchDataInicial === searchDataFinal)
      return `${dayStart} de ${renderMouth(Number(mouthStart))}, ${yearStart}`;
    if (yearStart === yearEnd && mouthStart === mouthEnd) {
      return `${dayStart} a ${dayEnd} de ${renderMouth(
        Number(mouthStart),
      )}, ${yearStart}`;
    }
    if (yearStart === yearEnd && mouthStart !== mouthEnd) {
      return `${dayStart} de ${renderMouth(
        Number(mouthStart),
      )} a ${dayEnd} de ${renderMouth(Number(mouthEnd))}, ${yearStart}`;
    }
    return `${dayStart} de ${renderMouth(
      Number(mouthStart),
    )}, ${yearStart} a ${dayEnd} de ${renderMouth(
      Number(mouthEnd),
    )}, ${yearEnd}`;
  }, [renderMouth, searchDataFinal, searchDataInicial]);

  useEffect(() => {
    loadAgenda(searchDataInicial, searchDataFinal);
  }, [loadAgenda, searchDataFinal, searchDataInicial]);

  useEffect(() => {
    const scheduleFilter = filterSchedulesData(agendasAll, search);

    setPendingShedule(scheduleFilter.filter(schedule => !schedule?.status));

    setInProgressShedule(
      scheduleFilter.filter(schedule => schedule?.status === 'Andamento'),
    );

    setCompleteShedule(
      scheduleFilter.filter(schedule => schedule?.status === 'Completo'),
    );

    setIncompleteShedule(
      scheduleFilter.filter(schedule => schedule?.status === 'Incompleto'),
    );
  }, [agendasAll, search]);

  return (
    <Container>
      <ContainerTitle>
        <header>
          <h1>Dashboard</h1>

          <Tooltip
            title="Nessa página você tem acesso a visualização da dashboard para fim de acompanhamento de métricas, integradada com uma apresentação do Power Bi a sua escolha"
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </header>
        <div>
          <button
            type="button"
            className={isModeVisibleCanva ? 'selected' : 'notSelec'}
            onClick={() => setIsModeVisibleCanva(true)}
          >
            <BsGridFill />
          </button>
          <button
            type="button"
            className={!isModeVisibleCanva ? 'selected' : 'notSelec'}
            onClick={() => setIsModeVisibleCanva(false)}
          >
            <BsListUl />
          </button>
        </div>
      </ContainerTitle>
      <Content>
        <ContainerSearch isFilterDateInterval={dataFilterModal[3].status}>
          {!dataFilterModal[3].status ? (
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder="Buscar"
            />
          ) : (
            <>
              <Search
                onSearch={(value: string) => {
                  setSearchDataInicial(value);
                }}
                nomePlaceHolder="Buscar data inicial"
                type="date"
              />

              <Search
                onSearch={(value: string) => {
                  setSearchDataFinal(value);
                }}
                nomePlaceHolder="Buscar data final"
                type="date"
              />
            </>
          )}

          <button type="button" onClick={() => setShowModalFilter(true)}>
            <img src={IconFilter} alt="iconFilter" title="Filtrar" />
          </button>
        </ContainerSearch>
        <HeaderDate>
          <h2>{dateRender}</h2>
        </HeaderDate>
        <S.WrappersLists isModeVisibleCanva={isModeVisibleCanva}>
          <S.ItemList>
            <S.ContainerHeaderList isModeVisibleCanva={isModeVisibleCanva}>
              <S.TitleList>
                Incompleto
                <Tooltip
                  title="Estas tarefas ainda estão pendentes de conclusão e requerem ação."
                  arrow
                  TransitionComponent={Zoom}
                >
                  <span className="incomplete">
                    <AiOutlineQuestionCircle />
                  </span>
                </Tooltip>
              </S.TitleList>
            </S.ContainerHeaderList>
            <S.ContainerList isModeVisibleCanva={isModeVisibleCanva}>
              {incompleteShedule.map((schedule, index) => {
                if (schedule?.status === 'Incompleto') {
                  return (
                    <NewCardAgenda
                      onPress={() => {
                        setShowAgenda(schedule);
                        setShowModal(true);
                      }}
                      schedule={schedule}
                      key={index + schedule.id}
                      colorStatus="#ffa500"
                    ></NewCardAgenda>
                  );
                }
              })}
              {!incompleteShedule?.length && <CardEmpty />}
            </S.ContainerList>
          </S.ItemList>

          <S.ItemList>
            <S.ContainerHeaderList isModeVisibleCanva={isModeVisibleCanva}>
              <S.TitleList>
                Feito{' '}
                <Tooltip
                  title="Estas tarefas foram concluídas com sucesso e não requerem mais ação."
                  arrow
                  TransitionComponent={Zoom}
                >
                  <span className="complete">
                    <AiOutlineQuestionCircle />
                  </span>
                </Tooltip>
              </S.TitleList>
            </S.ContainerHeaderList>
            <S.ContainerList isModeVisibleCanva={isModeVisibleCanva}>
              {completeShedule.map((schedule, index) => {
                if (schedule?.status === 'Completo') {
                  return (
                    <NewCardAgenda
                      onPress={() => {
                        setShowAgenda(schedule);
                        setShowModal(true);
                      }}
                      schedule={schedule}
                      key={index + schedule.id}
                      colorStatus="#00884A"
                    ></NewCardAgenda>
                  );
                }
              })}
              {!completeShedule?.length && <CardEmpty />}
            </S.ContainerList>
          </S.ItemList>
          <S.ItemList>
            <S.ContainerHeaderList isModeVisibleCanva={isModeVisibleCanva}>
              <S.TitleList>
                Fazendo
                <Tooltip
                  title="Estas tarefas estão atualmente em execução e estão em progresso ativo."
                  arrow
                  TransitionComponent={Zoom}
                >
                  <span className="in-progress">
                    <AiOutlineQuestionCircle />
                  </span>
                </Tooltip>
              </S.TitleList>
            </S.ContainerHeaderList>
            <S.ContainerList isModeVisibleCanva={isModeVisibleCanva}>
              {inProgressShedule.map((schedule, index) => {
                if (schedule?.status === 'Andamento') {
                  return (
                    <NewCardAgenda
                      onPress={() => {
                        setShowAgenda(schedule);
                        setShowModal(true);
                      }}
                      schedule={schedule}
                      key={index + schedule.id}
                      colorStatus="#ffd700"
                    ></NewCardAgenda>
                  );
                }
              })}
              {!inProgressShedule?.length && <CardEmpty />}
            </S.ContainerList>
          </S.ItemList>
          <S.ItemList>
            <S.ContainerHeaderList isModeVisibleCanva={isModeVisibleCanva}>
              <S.TitleList>
                A fazer
                <Tooltip
                  title="Estas tarefas ainda estão na lista de pendências e aguardam ação futura."
                  arrow
                  TransitionComponent={Zoom}
                >
                  <span className="pending">
                    <AiOutlineQuestionCircle />
                  </span>
                </Tooltip>
              </S.TitleList>
            </S.ContainerHeaderList>
            <S.ContainerList isModeVisibleCanva={isModeVisibleCanva}>
              {pendingShedule.map((schedule, index) => {
                if (!schedule?.status) {
                  return (
                    <>
                      <NewCardAgenda
                        onPress={() => {
                          setShowAgenda(schedule);
                          setShowModal(true);
                        }}
                        schedule={schedule}
                        key={index + schedule.id}
                        colorStatus="#3399ff"
                      ></NewCardAgenda>
                    </>
                  );
                }
              })}
              {!pendingShedule?.length && <CardEmpty />}
            </S.ContainerList>
          </S.ItemList>
        </S.WrappersLists>
      </Content>
      {loading && <Loading />}
      <ModalAgendaDash
        agenda={showAgenda}
        onClose={() => setShowModal(false)}
        visible={showModal}
        date={String(showAgenda.dataAgend)}
      />
      <ModalFilter
        isOpen={showModalFilter}
        type="dashAgenda"
        onRequestClose={() => setShowModalFilter(false)}
        onReturnData={e => setDataFilterModal(e)}
      />
    </Container>
  );
};
